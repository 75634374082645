@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,600;1,700&display=swap');
#LandingTitle{

    font-size: 4.3rem;
    font-family: 'Roboto Mono', monospace;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
}

#LandingBody{
    font-family: 'Roboto Mono', monospace;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #fff;
    text-align: center;
}